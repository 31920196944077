import React, { useState, useEffect } from 'react';
import Sidebar from '../sidebar/sidebar';
import Header from '../sidebar/header';
import List from '../List/List';



const Home = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);


  useEffect(() => {
    // Parse query parameters
    const params = new URLSearchParams(window.location.search);
 
    // Check if the "authenticated" parameter is present
    const isAuthenticated = params.has('authenticated');
    const token = params.get('token');
   

    if ((isAuthenticated || localStorage.getItem("isAuthenticated")) && token != null) {
      // The user is authenticated, perform necessary actions
     
      localStorage.setItem("isAuthenticated", isAuthenticated);
      localStorage.setItem("token", token);
      
      // Update the URL without query parameters
      const baseUrl = window.location.origin + window.location.pathname;
      console.log(baseUrl);
      //window.location.href =  baseUrl;
    } else {
      // The user is not authenticated
     
      window.location.href = process.env.REACT_APP_REDIRECT_URL;
    }

  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex flex-row md:flex-row bg-[#F9FAFB] min-h-screen">
      {isSidebarOpen && (
        <div className="hidden md:block w-full md:w-64">
          <Sidebar />
        </div>
      )}
      <div className="flex flex-col flex-grow overflow-hidden">
        <Header onIconClick={toggleSidebar} />
        <div className="flex flex-col flex-grow overflow-auto">
          <List />
        </div>
      </div>
    </div>


  );
};

export default Home;
